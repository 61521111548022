$(function() {
	$(".header__menu-link").click(function() {
		$(".header__menu-link").removeClass("active");         
		$(this).toggleClass("active");
	})
});

$(function() {
	$(".swiper-pagination-bullet").click(function() {
		$(".swiper-pagination-bullet").removeClass("active");         
		$(this).toggleClass("active");
	})
});

$(window).scroll(function() {
    if ($(this).scrollTop() > 50) {
        $('.to-top').addClass('scrolled');
    } else {
        $('.to-top').removeClass('scrolled');
    }
});


$(document).ready(function(){
	$('.portfolio__item-icon').on('click', function () {
		$(this).next().toggleClass('active');
	});
});

$('.product-card__order--1').click(function() {
	$('body').addClass('no-scroll');
	$('.popup-order--1').addClass('active');
});

$('.product-card__order--2').click(function() {
	$('body').addClass('no-scroll');
	$('.popup-order--2').addClass('active');
});

$('.product-card__order--3').click(function() {
	$('body').addClass('no-scroll');
	$('.popup-order--3').addClass('active');
});


const headerHeight = $('.header').outerHeight();
$('a[href^="#"]').click(function() {
	let elementClick = $(this).attr("href").replace(/#/, '');
	if (elementClick && $('a[name=' + elementClick + ']').length) {
		let destination = $('a[name=' + elementClick + ']').offset().top;
		$('html, body').animate({ scrollTop: destination - headerHeight }, 200);
		return false;
	}
});

// $(function() {
// 	let header = $('.header');
// 	let hederHeight = header.height(); // вычисляем высоту шапки
	 
// 	$(window).scroll(function() {
// 	  if($(this).scrollTop() > 1) {
// 	   header.addClass('header_fixed');
// 	   $('.main').css({
// 		  'paddingTop': hederHeight+'px' // делаем отступ у body, равный высоте шапки
// 	   });
// 	  } else {
// 	   header.removeClass('header_fixed');
// 	   $('.main').css({
// 		'paddingTop': 0 // удаляю отступ у body, равный высоте шапки
// 	   })
// 	  }
// 	});
// });


let menuBtn = document.querySelector('.header__burger-btn');
let menu = document.querySelector('.header__nav');
let body = document.querySelector('body');

menuBtn.addEventListener('click', function(){
	menuBtn.classList.toggle('active');
	menu.classList.toggle('active');
	body.classList.toggle('no-scroll');
})

$(document).ready(function () {
 
    var show = true;
    var countbox = ".info-block__list";
    $(window).on("scroll load resize", function () {
        if (!show) return false; // Отменяем показ анимации, если она уже была выполнена
        var w_top = $(window).scrollTop(); // Количество пикселей на которое была прокручена страница
        var e_top = $(countbox).offset().top; // Расстояние от блока со счетчиками до верха всего документа
        var w_height = $(window).height(); // Высота окна браузера
        var d_height = $(document).height(); // Высота всего документа
        var e_height = $(countbox).outerHeight(); // Полная высота блока со счетчиками
        if (w_top + 400 >= e_top || w_height + w_top == d_height || e_height + e_top < w_height) {
            $('.info-block__item-num p').spincrement({
                thousandSeparator: "",
                duration: 7000
            });
             
            show = false;
        }
    });
 
});

//показать еще
$(function () {
    $(".section-text p").slice(0, 2).show();
    $(".morelink").on('click', function (e) {
        e.preventDefault();
        $(".section-text p:hidden").slice(0, 9999).slideDown(300);
        if ($(".section-text p:hidden").length == 0) {
            $("#load").fadeOut('slow');
        }
    });
    return false;
});


// $(function(){
//     $(".section-text p").slice(0, 2).show();
// 	$('.loadMorei').click(function(){
// 		$('.section-text p').slideToggle(300, function(){
// 			if ($(this).is(':hidden')) {
// 				$('.content_toggle').html('Показать текст');
// 				$('.content_toggle').removeClass('open');
// 			} else {
// 				$('.content_toggle').html('Скрыть текст');
// 				$('.content_toggle').addClass('open');
// 			}							
// 		});
// 		return false;
// 	});
// });



// $(document).ready(function() {
//     // Configure/customize these variables.
//     var showChar = 100;  // How many characters are shown by default
//     var ellipsestext = "...";
//     var moretext = "Читать далее >";
//     var lesstext = "Скрыть";
    

//     $('.section-text').each(function() {
//         var content = $(this).html();
 
//         if(content.length > showChar) {
 
//             var c = content.substr(0, showChar);
//             var h = content.substr(showChar, content.length - showChar);
 
//             var html = c + '<span class="moreellipses">' + ellipsestext+ '&nbsp;</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink">' + moretext + '</a></span>';
 
//             $(this).html(html);
//         }
 
//     });
 
//     $(".morelink").click(function(){
//         if($(this).hasClass("less")) {
//             $(this).removeClass("less");
//             $(this).html(moretext);
//         } else {
//             $(this).addClass("less");
//             $(this).html(lesstext);
//         }
//         $(this).parent().prev().toggle();
//         $(this).prev().toggle();
//         return false;
//     });
// });
